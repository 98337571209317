import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../helper/Firebase";
import {useState} from "react";
import ReactPixel from "react-facebook-pixel";
import {useAppSelector} from "../store/types";
import {useSubscriptionData} from "./useSubscriptionData";

const useSubscribe = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {items, subscriptionName} = useAppSelector(state => state.subscription)
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products);
  const {email} = useAppSelector(state => state.auth)
  const {couponsList} = useSubscriptionData()

  function errorPay(message: string) {
    setErrorMessage(message || '');
    if (selectedProduct) {
      const event = {
        Subscription_name: subscriptionName,
        // Trial_days: item.trialPeriodDays,
        Currency: selectedProduct.currency,
        Price_local: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        Value: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        Error: message
      }
      mixpanel.track('payment_failed_web', event)
      logEvent(analitics, 'payment_failed_web', event)
    }
  }

  async function successPay() {
    if (selectedProduct) {
      const event = {
        Subscription_name: selectedProduct.name,
        // Trial_days: item.trialPeriodDays,
        Currency: selectedProduct.currency,
        Price_local: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        Value: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
      }
      ReactPixel.track('payment_complete_web', {
        Value: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
      })
      ReactPixel.track('Purchase', {
        value: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        currency: selectedProduct.currency
      })
      mixpanel.track('payment_complete_web', event)
      logEvent(analitics, 'payment_complete_web', event)
    }
  }

  const sendEventPaymentStartWeb = (from: string) => {
    if (selectedProduct) {
      const event = {
        Subscription_name: selectedProduct.name,
        // Trial_days: item.trialPeriodDays,
        Currency: selectedProduct.currency,
        Price_local: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        Value: selectedProduct.price - (couponsList[couponIndex]?.price || 0),
        Mail: email,
        paymentMethod: from
      }
      mixpanel.track('payment_start_web', event)
      logEvent(analitics, 'payment_start_web', event)
    }

  }

  return {
    successPay,
    errorPay,
    sendEventPaymentStartWeb,
    errorMessage,
  }
}

export default useSubscribe
