import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import OnBoardingLayout from "../layout/OnBoardingLayout";
import AuthPage from "../pages/Auth";
import Home from "../pages/Home/Home";
import Complete from "../pages/Complete/Complete";
import Install from "../pages/Install/Install";
import {useScreenHeight} from "../context/ScreenHeightContext";
import {useEffect, useMemo, useRef, useState} from "react";
import {useAppSelector} from "../store/types";
import NotFoundScreen from "../pages/NotFoundPage";


const NavigationRoutes = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const {routeName} = useAppSelector(state => state.onBoarding)
  const {screenHeight, isWebView} = useScreenHeight()
  const [overflow, setOverflow] = useState('hidden')
  const location = useLocation()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0,0);
    }
  }, [location]);
  useEffect(() => {
    document.documentElement.scrollTo(0,0);
    if (ref.current) {
      ref.current.scrollTo(0,0);
    }
    setOverflow(isWebView && window.screen.width <= 1024 && routeName === 'home' ? 'auto' : "auto")
  }, [routeName]);
  useEffect(() => {
    if (isWebView) {
      // document.documentElement.style.height = `${isWebView ? screenHeight - 75 : screenHeight}px`;
      // document.documentElement.style.minHeight = window.screen.width <= 540 ? `${isWebView ? screenHeight - 80 : screenHeight}px` : 'auto';
      // document.documentElement.style.maxHeight = window.screen.width <= 540 ? `${isWebView ? screenHeight - 80 : screenHeight}px` : 'auto';
      document.documentElement.style.overflow = isWebView ? 'hidden' : 'auto'
    }
  }, [isWebView]);

  const stylesForContainer  = useMemo(() => {
    console.log(isWebView)
    if (isWebView) {
      let height = screenHeight - 80;
      return {
        height: height,
        // minHeight: window.screen.width <= 540 ? (isWebView ? screenHeight - 80 : screenHeight) : 'auto',
        // maxHeight: window.screen.width <= 540 ? (isWebView ? screenHeight - 80 : screenHeight) : 'auto',
        overflow: 'hidden',

      }
    } else {
      if ( routeName === 'home') {
        let height = screenHeight;
        return {
          height: height,
          // minHeight: window.screen.width <= 540 ? (isWebView ? screenHeight - 80 : screenHeight) : 'auto',
          // maxHeight: window.screen.width <= 540 ? (isWebView ? screenHeight - 80 : screenHeight) : 'auto',
          overflow: 'hidden',

        }
      } else {
        if (window.screen.width <= 540) {
          return {
            height: '100dvh'
          }
        } else {
          return {}
        }
      }
    }
  }, [isWebView, routeName])
  return (
    <div ref={ref} className={'hideScrollbar'} style={{...stylesForContainer, display: window.screen.width <= 540 ? 'flex' : 'block',
      flexDirection: 'column',}}>
      <div ref={containerRef} className={"hideScrollbar"} style={{
        overflow: isWebView && window.screen.width <= 540 ? 'auto' : 'inherit',
        backgroundColor: routeName !== 'home' ? 'white' : "white",
        overflowX: 'hidden',
        height: !isWebView && window.screen.width <= 540 ? '100dvh' : '100dvh',
      }}>
        <Switch>
          {/* Роуты */}
          <Route exact path="/" component={Home}/>
          <Route path="/auth" component={AuthPage}/>
          <Route path="/onboarding" component={OnBoardingLayout}/>
          <Route path="/complete" component={Complete}/>
          <Route path="/install" component={Install}/>
          <Route path="*" component={NotFoundScreen}/>
        </Switch>
      </div>
    </div>
  )
}

export default NavigationRoutes
