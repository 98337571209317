export const useSubscriptionData = () => {
  let couponsList = [];
  let productsIds: string[] = [];
  let subdcriptionList: string[] = [];
  let paypalOptions = {
    clientId: '',
    "enable-funding": "",
    "disable-funding": "paylater,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };
  if (window.location.hostname.includes("eatr.com")) {
    paypalOptions.clientId = 'AbJ_bofxA8YF1L2-dlACb_7VthyBLPHU8Ndj4uxZRevOQh0fKEgcSqQaF-1LM-C68vTNtIj1w4wux6tR';
    subdcriptionList = ['P-20D45857DG1433436M2HJ7DA', 'P-5UN900468P461160FM2HKANQ', 'P-78300738XU370562MM2HKBFY'];
    productsIds = ['prod_QRu8R0k1HuGeJv', 'prod_QRuEAtagkFsG16', 'prod_QRuFuW4it2ofw1'];
    couponsList = [
      {
        id: 'YB9cxPGf',
        price: 2
      },
      {
        id: '1nlBumpc',
        price: 7
      },
      {
        id: 'lnAjf61g',
        price: 15
      },
    ]
  } else {
    paypalOptions.clientId = 'Ad781XMoQ5cY35gDwtB42Bd84Oi7IEQUqm1nbv1zVX7VXq7THwWtD5f4LZDJJvj_8Tj0Hdfu5vst1ekG'
    subdcriptionList = ['P-4C449578FJ941161RM2GVAWY', 'P-6RW62951KW4942126M2GVCEQ', 'P-31B72448NP845045DM2GU7II']
    productsIds = ['prod_QRC9fzfJS3wFMz', 'prod_QRC6apV8O5l2Xb', 'prod_QPds318THKN2xD'];
    couponsList = [
      {
        id: 'JuBWMqrb',
        price: 2
      },
      {
        id: '4VfuIjjh',
        price: 7
      },
      {
        id: '5lSGQpEv',
        price: 15
      },
    ]
  }

  return {
    couponsList,
    productsIds,
    subdcriptionList,
    paypalOptions,
  }
}
